<template>
  <LandingComponent />
</template>

<script>
import LandingComponent from "@/components/Landing.vue";

export default {
  name: 'HomeView',
  components: {
    LandingComponent
  }
}
</script>
