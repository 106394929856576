<template>
    <article>
        <h3>{{ post.title }}</h3>
        <p>
            {{ post.content.replaceAll(/<[^>]*>/g, "").substring(0,150) + '...' }}
        </p>
        <time :datetime="post.date">{{ formatDate }}</time>
        <router-link :to="`/post/${post.id}`">READ FULL POST</router-link>
    </article>
</template>

<script>
export default {
    name: 'PostComponent',
    props: {
        post: {
            type: Object,
        }
    },
    computed: {
        formatDate() {
            const date = this.post.date;
            let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

            let interval = seconds / 2592000;
            if (interval > 1) {
                return date.toLocaleDateString(navigator.language);
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

article {
    text-align: left;
    margin: 2rem;

    color: $text-secondary-color;
    
    background-color: $secondary-color;
    padding: 1rem;
    border-radius: 0.5rem;
    
    line-height: 1.1;
    letter-spacing: 0.05rem;

    p, h3 {
        color: $text-primary-color;
    }

    p {
        margin-top: 0.25rem;
        color: $text-secondary-color;
    }

    time {
        margin-top: 0.5rem;
        font-size: .8rem;
        display: block;
    }

    a {
        display: block;
        font-size: 0.8rem;
        margin-top: 1rem;
    }

    &::before {
        content: "";
        position: absolute;
        left: calc(-1rem - 1px);
        width: 1rem;
        height: 1rem;
        border: 0.5rem solid $primary-color;
        border-radius: 50%;
        background-color: $accent-color;
    }
}
</style>