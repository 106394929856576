<template>
  <MeComponent />
  <footer></footer>
  <router-view/>
</template>

<script>
import MeComponent from "@/components/Me.vue"

export default {
  name: 'App',
  components: {
    MeComponent
  }
}
</script>

<style lang="scss">
@import "./assets/scss/reset";
@import "./assets/scss/variables";

body {
  background-color: $primary-color;
}

#app {
  background-color: $primary-color;
  color: $text-primary-color;
  height: 100vh;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  display: grid;
  grid-template-areas: 
    "me"
    "content"
    "content"
    "content";

  grid-template-columns: 100%;
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

footer {
  grid-area: footer;
}

a {
  color: $accent-color;
  font-weight: bold;
  text-decoration: none;
}

.content-title {
  font-size: 1.2rem;
  font-weight: bold;
}

@media only screen and (min-width:768px){
  #app {
    grid-template-areas: 
      "me content content content" 
      "me content content content" 
      "footer content content content" 
      "footer content content content" 
      "footer content content content";
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
</style>
