<template>
    <section>
        <div>
            <img src="@/assets/img/me.jpg" alt="Liam">
            <div>
                <h1>Liam Deferm</h1>
                <ul class="socials">
                    <li><a href="https://www.linkedin.com/in/liam-deferm-448865208/" target="_blank"><ion-icon name="logo-linkedin"></ion-icon></a></li>
                    <li><a href="https://github.com/LiamD-Flop" target="_blank"><ion-icon name="logo-github"></ion-icon></a></li>
                    <!-- <li><a href=""></a></li> -->
                </ul>
                <div>
                    <a target="_blank" href="https://wakatime.com/@f4283a81-d7ae-4c58-8896-3b3e711ac8a1"><img src="https://wakatime.com/badge/user/f4283a81-d7ae-4c58-8896-3b3e711ac8a1.svg" alt="Total time coded since Sep 5 2018" /></a>
                </div>
            </div>
            
        </div>
        <!-- <nav>
            <router-link to="/">Home</router-link>
        </nav> -->
    </section>
</template>

<script>
export default {
    name: 'MeComponent'
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables";

    section {
        margin: 1rem 1rem 0 1rem;
        padding: 1rem;
        grid-area: me;
        border-radius: 0.5rem;

        background-color: $secondary-color;

        z-index: 100;

        & > div {
            margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            height: 100%;

            img {
                border-radius: 50%;
                max-width: 40%;
                object-fit: contain;
            }

            div {
                img {
                    border-radius: 0;
                    max-width: 90%;
                }
                h1 {
                    font-size: 1.5rem;
                }
            }
        }

        ul.socials {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            font-size: 1.5rem;

            li {
                margin: 0.25rem;
            }
        }
    }
    @media only screen and (min-width:768px){
        section {
            margin-bottom: 1rem;
            
            & > div {
                flex-direction: column;
                img {
                    max-width: 100%;
                }
            }

            ul.socials {
                margin-top: 1rem;
            }
        }
    }
</style>