<template>
  <section>
      <article>
            <h2>Hi there!</h2>
            <!--
                I'm Liam, 20 year old and currently in my last year at howest brugge studying to become a Cyber Security Professional
                My hobbies include working on fun side projects and doing some nature and car photography.
             -->
            <p>
                I'm Liam, a 20 year old Junior Cyber Security Professional currently finishing his Bachelors degree <a href="https://www.howest.be/en" target="_blank" rel="noopener noreferrer">@Howest Brugge</a>.
                <br />In my free time, I like to work on fun side projects and do some nature and car photography.
            </p>
      </article>
      <section class="posts">
            <div v-for="post in posts" :key="post.id">
                <EventComponent v-if="post.type === 'event'" :post="post" />
                <PostComponent v-if="post.type === 'post'" :post="post" />
            </div>
      </section>
  </section>
</template>

<script>
import EventComponent from "@/components/Event.vue";
import PostComponent from "@/components/Post.vue";

export default {
    name: 'LandingComponent',
    components: {
        EventComponent,
        PostComponent
    },
    computed: {
        posts() {
            let localPosts = [...this.$store.state.posts];
            return localPosts.sort((a, b) => b.date - a.date);
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables";

    section:not(.posts) {
        grid-area: content;
        overflow-y: auto;
        padding-top: 1rem;

        /* scrollbar */
        ::-webkit-scrollbar {
            width: 0.25em;
        }
        ::-webkit-scrollbar-track {
            background: var(--primary-black);
        }
        ::-webkit-scrollbar-thumb {
            background: var(--primary-rust);
            border-radius: 0.25em;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: var(--secondary-rust);
        }

        animation: fadeIn 1s forwards ease-in-out;

        & > article:first-of-type {
            position: relative;

            text-align: left;
            margin: 1rem 5% 0 5%;
            line-height: 1.3;
            letter-spacing: 0.1rem;

            padding: 1rem;
            border-radius: 0.5rem;

            background-color: $secondary-color;

            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                left: 4rem;
                top: -1rem;
                border-style: solid;
                border-width: 0 1.5rem 1rem 1.5rem;
                border-color: transparent transparent $secondary-color transparent; 
            }
        }

        & > section {
            position: relative;
            padding: 1rem 1rem 1rem 0;
            border-left: 3px solid $secondary-color;
            border-bottom: 3px solid $secondary-color;
            margin: 0 0 1rem 2.5rem;
        }
    }

    @media only screen and (min-width:768px){
        section:not(.posts) {
            & > article:first-of-type {
                margin: 5rem 5% 0 5%;
                border-top-left-radius: 0;

                &::before {
                    left: -2rem;
                    top: 0;
                    border-width: 0 2rem 2rem 0;
                    border-color: transparent $secondary-color transparent transparent; 
                }
            }

            & > section {
                margin: 0 5rem;
            }
        }
    }

    @keyframes fadeIn {
        0% {
            transform: translateY(-2rem);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
</style>