import { createStore } from 'vuex'

export default createStore({
  state: {
    posts: [
      {
        type: "post",
        id: 1,
        title: "My day attending Digital Experience 2022.",
        date: new Date("2022/05/18"),
        image: "https://www.dataexpert.eu/media/qi4lgg1r/dataexpert_logo_pms_digitalexperience.png?width=985&quality=80",
        content: `
        I had the pleasure to attend Digital Experience 2022 in the Van der Valk Hotel at Brussels Airport.<br />
        Excited to go, I departed to the train station and it wouldn't be a normal day using the belgian train infrastructure without there being any problems.<br />
        All the trains were canceled as there was an accident between my station and the station in Leuven. So I had to take a train going to Luik (the complete other side of the country).<br />
        So I did. Skipping forwards 2 hours, I finally arrived at the Van der Valk Hotel. Luckely the welcome talk hadn't finished so I was able to attend the first seminar.<br /><br />
        <h3 class="content-title">OSINT Investigations</h3>
        I decided, for my first seminar of the day, to follow the OSINT seminar.<br />
        This talk was especially interesting to me, as my thesis is about OSINT and SOCMINT investigations.<br />
        The seminar was focused on OSINT as a way for law enforcement to find intel on their targets.<br />
        After the seminar I had a chat with the speaker and he gave me some tips on how I could improve my thesis.<br /><br />
        <h3 class="content-title">NFT's & the Metaverse</h3>
        The second seminar of the day was about NFT's and the Metaverse.<br />
        Starting of we got an introduction on how the different blockchains work. Knowing this information we went straight into the world of NFT's.<br />
        We went over how a NFT works on the blockchain, what different types of NFT's there are and how they integrate into the Metaverse.<br />
        Next up, you guessed it, the Metaverse.<br />
        The Metaverse is a way developers integrate the blockchain and nft's into games. The talk was about how big companies like Ubisoft and Adidas are building their business in the Metaverse.<br />
        We finished the seminar talking about the different ways the Metaverse has given criminals a way to communicate and do their business in a concealed way.<br /><br />
        <h3 class="content-title">Finishing the day strong!</h3>
        I had a hard time deciding what seminar I wanted to follow to finish of this event. I eventually decided to attend the finishing seminar on blockchain. This because of how interesting the previous session was.<br />
        In the finishing seminar they talked about chainalysis, and how they change the way investigators can look into blockchain investigations.<br />
        Chainalysis works together with most of the well known crypto exhanges and gives them a way to be compliant with laws and regulations.<br />
        At the same time they give investigators a way to put names on and visualize blockchain transaction.<br />
        They give info to the exhanges on known addresses that are used for illicit activities, this way the exhanges can block these.<br />
        Finishing of this session we got a view into law enforcement investigations that included a blockchain investigation.<br /><br />
        I have to say, I was excited about this event as the topics really interested me. And it did not dissapoint.<br />
        I'm definitly looking forward to going again next year!
        `
      },
      {
        type: "event",
        id: 2,
        title: "Tickets booked for leHack 2022!!",
        date: new Date("2022/05/17"),
      },
      {
        type: "event",
        id: 3,
        title: "I started my internship at PwC Belgium!",
        date: new Date("2022/02/14"),
      },
      {
        type: "event",
        id: 6,
        title: "Tickets booked for Digital Experience 2022.",
        date: new Date("2022/04/18"),
      },
      {
        type: "post",
        id: 4,
        title: "Talking to the future.",
        date: new Date("2021/11/15"),
        content: `
        Today I had the opportunity to go out and talk about the course I followed in Howest Brugge.<br />
        This talk was at my old high school. They organise a week where old students come back and give an explanation on the school and course they follow.<br />
        During this talk we had a lot of questions about the type of lectures we have and if it is really "all about hacking". We gave them the needed motivation on why they should choose this school, and for what the future holds.<br />
        We can only hope for the best!
        `
      },
      {
        type: "post",
        id: 7,
        title: "We're one a roll.",
        date: new Date("2022/03/15"),
        image: "https://miro.medium.com/max/1400/1*mm1_mK2_AN-pwUgFdCiUVQ.png",
        content: `
        After doing the first CTF, I really got hooked in the concept of doing these challenges. Luckely for me, a week after the first one I did there was another one<br />
        And being in the same team as before, we really got a great dynamic going. This time we joined the PicoCTF. In terms of challenges this CTF looked a lot like the previous one we did.<br />
        The challenges were aimed at beginner so it was perfect to see what I with my current knowledge could do.<br />
        Starting out, everyone started working on a different challenge. This way we were tackling a lot of different category's at a time.<br />
        After the first couple of hours we already did a lot of the beginner challenges. This, ofcourse, is when it got a bit more difficult. Wanting something new to try. I started working on some reversing challenges.<br />
        Being a bit new to the space, it took some time getting used to it. But after a bit of stuggles, I solved my first reversing challenge.<br />
        Afterwards we all worked together trying to finish the last of the web challenges. When the CTF ended we ended up in the top 10%. And we were of course happy with this result.
        `
      },
      {
        type: "post",
        id: 5,
        title: "My first CTF.",
        date: new Date("2022/03/11"),
        image: "https://i.ytimg.com/vi/oA5d8IYUUbc/maxresdefault.jpg",
        content: `
        I always wanted to try and do a CTF and hearing this, a colleague of mine told me to join him in this CTF.<br />
        So that is how it started. I got invited to his team and joined them doing the 1337UP Intigriti CTF.
        During this CTF I mainly focused on crypto and web challenges.<br /><br />
        I started out doing some basic crypto decryptions. During my cryptography course in Howest I worked on some scripts to work with encryptions. This helped out a lot during this part of the CTF.<br />
        After a while I wanted to try something different and it was at this point that one of my teammembers asked for some help on a web challenge about Flask.<br />
        He asked me because, at this time, I was using a lot of Flask during my internship. After reading through the code, I noticed there was a RCE vulnerability where we could add some code to the url and it would execute it.<br />
        And indeed, using this we could read the flag.txt file on the server. Jippie!!<br /><br />
        At this point it was late at night so I decided to get some sleep.<br />
        Now that it was the last day of the 2 days long CTF, I wanted to get another challenge. At this point we were also in the top 50 ( out of 600 ), finishing this high up we had a chance getting invited to their next event.<br />
        After a while working on a web CTF, together with some of the team members. One of them finally found a way to do RCE, but he didn't find a way to read the file system.<br />
        Using the progress he made I created a payload with which we were able to complete the challenge with.<br />
        After this the CTF ended we ended up just missing the invite to the next event. But we all learned a lot of new stuff so we're sure to get it next time!
        `
      },
      {
        type: "post",
        id: 8,
        title: "Joining a new project.",
        date: new Date("2022/02/21"),
        content: `
        On a quest to learn new things I decided to join in on a project of a friend of mine.<br />
        The project is about a mod menu for GTA V. Where the usual mod menu focusses on ruining the fun of others or giving the user money and xp. We'll be looking into ways to protect others from these people.<br />
        This by building in protections and ways for us to remove these people from the user their session.<br />
        During this time  I'll be learning C++ and I'll also be looking at ways to reverse engineer programs.
        `
      },
      {
        type: "post",
        id: 9,
        title: "3th time's the charm.",
        date: new Date("2022/05/19"),
        image: 'https://miro.medium.com/max/1400/0*r3hS0FFDbHjjcrWC.jpg',
        content: `
        Having a little time on my hands, and a team that was excited to do another CTF. We decided to join the Cyber Apocalypse 2022 CTF by HackTheBox.<br />
        Starting out our team did the first couple of introduction flags right out the gate. Afterwards we split up and started on a couple of challenges.<br />
        This time I started with some of the web challenges. The first challenge I tried was a invoice pannel where we could create invoices. They also provided us with the source code of the page.<br />
        The files provided had a docker file that was also used whenever someone spun up the challenge. Looking inside we could see that a flag.txt gets copied to the root directory.<br />
        Excited about this I tried to do some path traversal, but no luck. Next up, I went and took a look at how the invoices were made.<br />
        The tool that was used was md-to-pdf. So I went out to look for a vulnerability in this tool. And bingo!<br />
        md-to-pdf has a vulnerability where you can execute JavaScript that you've put into the markdown input. Using this we could create a little script that gets the contents of the /flag.txt and puts it into a file in the static directory.<br />
        I went and tested this, and sure enough. There was the flag.<br /><br />
        After this challenge I wanted to take a look at the reversing challenges they created.<br />
        I picked one to work on at random. The one I had was a vault where the flag was locked behind a password.<br />
        If we took a look at the decompiled code, we could see that the flag was in there but encrypted.<br />
        Taking a further look into the program, I found the function where the flag was read. Looking at it we could see the checks to see if the password you entered was right.<br />
        Something else I noticed is that the password isn't used to decrypt the flag. Rather they have a algorithm that decrypts it.<br />
        Knowing this we didn't have to try and figure out the password. We just took the encrypted password and recreated the script in python, and voila, we got the flag.<br />
        The next day I was more helping out, looking for ways to advance in the more challenging challenges.<br /><br />
        We eventually ended 457 / 7024. We were really happy with this result. And can't wait to play the next one!

        `
      },
      {
        type: "event",
        id: 10,
        title: "I was born.",
        date: new Date("2001/08/24")
      },
      {
        type: "event",
        id: 11,
        title: "I started economics and modern languages @ Sint-Janscollege Meldert",
        date: new Date("2013/09/01")
      },
      {
        type: "event",
        id: 12,
        title: "I started commerce @ Miniemeninstituut Leuven",
        date: new Date("2016/09/01")
      },
      {
        type: "event",
        id: 13,
        title: "I started IT management @ Miniemeninstituut Leuven",
        date: new Date("2017/09/01")
      },
      {
        type: "event",
        id: 14,
        title: "I got my high school diploma!",
        date: new Date("2019/06/21")
      },
      {
        type: "event",
        id: 15,
        title: "I started my journey becoming a Cyber Security Professional @ Howest Brugge!",
        date: new Date("2019/09/01")
      },
      {
        type: "post",
        id: 16,
        title: "Kubernetes secrets and how to store them.",
        date: new Date("2022/05/11"),
        image: "https://images.prismic.io/www-static/e7798719-3af9-42bf-8273-04f02108383f_digitalocean-vpc-blog-1.jpg?auto=compress,format",
        content: `
        Today I followed a talk on kubernetes secret storing by Digital Oceans.<br />
        I've been looking into kubernetes. And one of the things I didn't understand, is which of the secret storing methods to use.<br />
        This is when I got notified of the Digital Oceans Kubernetes talk. And decided to join.<br />
        They talked about the drawbacks and benefits of a CSI Secret store and external secrets operator.<br />
        Looking at both, they both give us the ability to store our secrets on an external source. This way if your kubernetes cluster gets compromised, your secrets will be somewhere else.<br />
        Some of the main differences is that CSI driver will behave as a kubernetes pod. This way your secrets aren't put into the cluster, instead they are a seperate computing unit the others can contact<br />
        A benefit of external secrets operators is that they can manage their secrets in namespaces. This way you can put secrets from one project into a namespace, so that this project can only fetch it's own secrets.<br />
        They finished up by doing a quiz to see what you've remembered from the session.<br />
        We also received the slides they used so we can consult this at a later day.
        `
      },
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
