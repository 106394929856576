<template>
    <article>
        <time datetime="2022-01-01">{{ formatedDate }}</time>
        <p>{{ post.title }}</p>
    </article>
</template>

<script>
export default {
    name: 'EventComponent',
    props: {
        post: {
            type: Object,
        }
    },
    computed: {
        formatedDate() {
            const date = this.post.date;
            let seconds = Math.floor((new Date() - date) / 1000);

            let interval = seconds / 2592000;
            if (interval > 1) {
                return date.toLocaleDateString(navigator.language);
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + ` day${Math.floor(interval) > 1 ? "s": ""} ago`;
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + ` hour${Math.floor(interval) > 1 ? "s": ""} ago`;
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + ` minute${Math.floor(interval) > 1 ? "s": ""} ago`;
            }
            return Math.floor(seconds) + ` second${Math.floor(interval) > 1 ? "s": ""} ago`;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

article {
    text-align: left;
    margin: 2rem;

    line-height: 1.2;

    color: $text-secondary-color;

    p, h3 {
        color: $text-primary-color;
    }

    &::before {
        content: "";
        position: absolute;
        left: calc(-1rem - 1px);
        width: 1rem;
        height: 1rem;
        border: 0.5rem solid $primary-color;
        border-radius: 50%;
        background-color: $accent-color;
    }
}
</style>